.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (min-width:320px)and(max-width:480px) {
  .data_gridresponsive {
    height: 400;
    width: '100%';
  }
}

#okta-sign-in .auth-header {
  padding: 0px 0px !important;
  border:none !important;
}

.org-logo {
  display: block;
  margin: 0 auto;
  max-width: 200px;
  max-height: 40px;
}

.o-form-head {
  font-size: 20px !important;
}

#okta-sign-in {
  margin-top: 0 !important;
}

#okta-sign-in .beacon-container {
  top: 25px;
}

@media screen and (min-height: 658px) {
  .custom-auth-container {
    padding: 50px !important;
  }

}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{
  background-color:#279989 !important;

}

#teconsent a, #trustarc-irm-placeholder a {
  text-decoration-color: rgba(39, 153, 137, 0.4);
}
#truste-consent-track {
  z-index: 9999999;
  transition: all 3s ease;
  bottom: unset;
  visibility: hidden;
  width: 69%;
  margin: auto auto auto auto;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.rmdp-input {
  height: 28px !important;
}

.error {
  border: 1px solid red !important;
}