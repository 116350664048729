.chip {
    display: inline-block;
    padding: 0 20px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    border-radius: 25px;
    color: #fff;
    margin: 5px;
}

.danger {
    background-color: red;
}

.success {
    background-color: green;
}

.badge {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
}

.disabled-slot {
    background-color: grey !important;
    color: #fff !important;
}

.blocked-slot {
    background-color: lightgrey !important;
    
}

.unblocked-slot {
    background-color: green !important;
    color: #fff !important;
}

.newly-unblocked-slot {
    background-color: red !important;
    color: #fff !important;
}